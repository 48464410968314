<template>
    <div class="d-flex flex-column flex-root">
        <div
            class="st-account-validation d-flex flex-row-fluid flex-column justify-content-center mx-auto"
        >
            <div class="card">
                <div class="card-header border-0 pt-14 pb-11">
                    <b-img :src="logoImage" width="25"/>
                </div>
                <div class="card-body d-flex flex-column pt-0 pb-14">
                    <h2
                        class="card-title font-weight-bold"
                    >
                        {{ $t('AUTH.VERIFY_SMS.TITLE') }}
                    </h2>
                    <div>
                        <p v-if="!isMessageSent" class="font-weight-normal pb-7">
                            {{ $t('AUTH.VERIFY_SMS.MESSAGE') }}
                        </p>
                        <i18n
                            v-else
                            path="AUTH.VERIFY_SMS.MESSAGE_SENT"
                            tag="label"
                            class="font-weight-normal pb-7"
                        >
                            <span class="font-weight-bold">{{ phone }}</span>
                        </i18n>
                        <form
                            v-show="!isMessageSent"
                            class="form"
                            novalidate="novalidate"
                            id="st_send_form"
                            ref="st_send_form"
                        >
                            <div
                                class="form-group">
                                <label>
                                    {{ $t('AUTH.INPUT.PHONE') }}</label
                                >
                                <input
                                    class="form-control h-auto py-4 px-6"
                                    type="text"
                                    placeholder="0745 123 456"
                                    name="phone"
                                    v-model="phone"
                                    autocomplete="off"
                                    ref="phone"
                                    @keypress="isPhone($event)"
                                />
                            </div>
                            <button
                                id="st_send_button"
                                ref="st_send_button"
                                class="btn btn-primary font-size-h6"
                            >
                                {{ $t('AUTH.VERIFY_SMS.BUTTON_SUBMIT') }}
                            </button>
                        </form>
                        <form
                            v-show="isMessageSent"
                            class="form"
                            novalidate="novalidate"
                            id="st_validation_form"
                            ref="st_validation_form"
                        >
                            <div
                                class="form-group">
                                <input
                                    class="form-control h-auto py-4 px-6"
                                    type="text"
                                    :placeholder="inputValidationCodePlaceholder"
                                    name="validationCode"
                                    v-model="validationCode"
                                    ref="validationCode"
                                    @keypress="isValidationCode($event)"
                                />
                            </div>
                            <button
                                class="btn btn-primary font-size-h6"
                                id="st_validate_button"
                                ref="st_validate_button"
                            >
                                {{ $t('AUTH.VERIFY_SMS.BUTTON_VALIDATE') }}
                            </button>
                            <div
                                class="mt-10">
                                <i18n
                                    path="AUTH.VERIFY_SMS.MESSAGE_NOT_RECEIVED"
                                    tag="span"
                                >
                                    <b-link @click="resendMessage" class="font-weight-bold">{{
                                        $t('AUTH.VERIFY_SMS.RESEND_LINK') }}
                                    </b-link>
                                </i18n>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-end py-7 py-lg-0 mb-10">
            <a href="#" class="text-primary font-weight-bold font-size-h6">{{
                $t('AUTH.GENERAL.PRIVACY')
                }}</a>
            <a href="#" class="text-primary ml-10 font-weight-bold font-size-h6">{{
                $t('AUTH.GENERAL.LEGAL')
                }}</a>
        </div>
    </div>
</template>
<script>
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

    // FormValidation plugins
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
    import SubmitButton from '@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton';

    import KTUtil from '@/assets/js/components/util';

    import { mapActions, mapState } from 'vuex';

    import { VERIFY_PHONE, VALIDATE_PHONE, AUTH_VERIFICATION_TOKEN, GET_PROFILE } from '../auth-store';

    export default {
        name: 'VerifySMS',
        data() {
            return {
                phone: '',
                validationCode: '',
                isValid: false,
                isMessageSent: false
            };
        },
        computed: {
            ...mapState({
                verificationToken: (state) => state.auth.verificationToken
            }),
            logoImage() {
                return `${process.env.BASE_URL}media/st-logo-stema.svg`;
            },
            inputValidationCodePlaceholder() {
                return this.$t('AUTH.INPUT.VALIDATION_CODE_PLACEHOLDER');
            }
        },
        methods: {
            ...mapActions({
                verifyPhone: `auth/${VERIFY_PHONE}`,
                validatePhone: `auth/${VALIDATE_PHONE}`,
                setVerificationToken: `auth/${AUTH_VERIFICATION_TOKEN}`,
                getProfile: `auth/${GET_PROFILE}`

            }),
            resendMessage() {
                this.validationCode = '';
                this.isMessageSent = false;
                this.fv.resetField('phone');
                this.fv1.resetField('validationCode');
            },
            isPhone(evt) {
                evt = (evt) ? evt : window.event;
                if (this.phone.length === 15) {
                    evt.preventDefault();
                }
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode !== 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            isValidationCode(evt) {
                evt = (evt) ? evt : window.event;
                if (this.validationCode.length === 6) {
                    evt.preventDefault();
                }
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        },
        created() {
            if (this.$route.query && this.$route.query.token) {
                this.setVerificationToken(this.$route.query.token);
            }
        },
        mounted() {
            const send_form = KTUtil.getById('st_send_form');
            const validation_form = KTUtil.getById('st_validation_form');

            this.fv = formValidation(send_form, {
                fields: {
                    phone: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            phoneNumber: {
                                message: this.$t('AUTH.VALIDATION.INVALID_PHONE')
                            },
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }

            });
            this.fv1 = formValidation(validation_form, {
                fields: {
                    validationCode: {
                        validators: {
                            notEmpty: {
                                message: this.$t('AUTH.VALIDATION.REQUIRED')
                            },
                            stringLength: {
                                min: 6,
                                max: 6,
                                message: this.$t('AUTH.VALIDATION.SMS_CODE_LENGTH'),
                            },
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });

            this.fv.on('core.form.valid', () => {
                const phone = this.$refs.phone.value;

                // set spinner to submit button
                const submitButton = this.$refs['st_send_button'];
                submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
                // dummy delay
                setTimeout(() => {

                    this.verifyPhone({ phone, token: this.verificationToken })
                        .then(() => {
                            this.isMessageSent = true;
                        })
                        .catch((err) => {
                            const status = err.response.status;
                            if (status === 400) {
                                this.$router.push({ name: 'invalidLink' });
                            }
                        });

                    submitButton.classList.remove(
                        'spinner',
                        'spinner-light',
                        'spinner-right'
                    );
                }, 2000);
            });

            this.fv1.on('core.form.valid', () => {
                const smsCode = this.$refs.validationCode.value;
                const phone = this.$refs.phone.value;
                // set spinner to submit button
                const submitButton = this.$refs['st_validate_button'];
                submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
                // dummy delay
                setTimeout(() => {

                    this.validatePhone({ phone, smsCode, token: this.verificationToken })
                        .then(() => {
                            this.getProfile().then(() => {
                                this.$router.push({ name: 'home' });
                            });
                        })
                        .catch(err => {
                            const status = err.response.status;
                            if (status === 400) {
                                this.$router.push({ name: 'invalidLink' });
                            }
                        });

                    submitButton.classList.remove(
                        'spinner',
                        'spinner-light',
                        'spinner-right'
                    );
                }, 2000);
            });
        }
    };
</script>

<style lang="scss">
    @import '@/assets/sass/pages/auth/account-validation.scss';
</style>
